import { inject } from '@angular/core';
import { NavController } from '@ionic/angular';
import { AuthService } from '../services/auth/auth.service';
import { Preferences } from '@capacitor/preferences';
import { Platform } from '@ionic/angular/standalone';
import { jwtDecode } from 'jwt-decode';

export async function authGuard() {
  const authService = inject(AuthService);
  const navCtrl = inject(NavController);
  const platform = inject(Platform);

  // Check jwt expiration
  let token: string | null = null;
  if (platform.is('capacitor')) {
    try {
      const { value } = await Preferences.get({ key: 'authenticationToken' });
      if (value) token = value;
    } catch (err) {
      console.error(err);
    }
  } else {
    token = localStorage.getItem('authenticationToken') || sessionStorage.getItem('authenticationToken');
  }

  if (token !== null) {
    const { exp } = jwtDecode(token);
    if (exp && Date.now() >= exp * 1000) {
      // token expired
      await authService.logout(false);
      return false;
    }
  }

  const account = authService.currentUser;
  if (!account) await authService.loadUser();

  if (authService.currentUser) return true;
  await navCtrl.navigateRoot('/login');
  return false;
}
